<template>
  <div>
    <div>
      <div class="edit-team-wrap">
        <div class="edit-team-item space-between" @click="editTeam">
          <p class="item-l">小队名称</p>
          <div class="item-r">
            <p>{{teamDetail.name}}</p>
          </div>
        </div>
        <div class="edit-team-item space-between">
          <p class="item-l">小队长</p>
          <div class="item-r vertical-center">
            <p>{{teamDetail.wechat ? teamDetail.wechat.nickname : '无'}}</p>
            <!--          <img src="~assets/images/user/right-icon.png" alt="">-->
          </div>
        </div>
        <div class="edit-team-item space-between" @click="teamNumber">
          <p class="item-l">小队成员</p>
          <div class="item-r vertical-center">
            <p>{{teamDetail.member_number ? teamDetail.member_number: 0}}人</p>
            <img src="~assets/images/user/right-icon.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!--创建小队弹框开始-->
    <van-popup round  v-model:show="edit_team">
      <div class="edit-team-pop">
        <div class="edit-team-top">
          <img src="~assets/images/user/edit-pop-close.png" @click="edit_team= false" class="close-icon" alt="">
        </div>
        <div class="edit-team-cont align-center">
          <input type="text" maxlength="15" v-model="name" placeholder="请输入小队名称" class="edit-team-inp">
        </div>
        <div class="btn-box align-center">
          <div class="btn cancel-btn" @click="edit_team= false">取消</div>
          <div class="btn confirm-btn" @click="editName">确定</div>
        </div>
      </div>
    </van-popup>
    <!--创建小队弹框结束-->
  </div>

</template>

<script>
import _ from 'lodash'
export default {
  name: "Edit",
  data() {
    return{
      edit_team: false,
      id: '',   // 小队id
      name: '', // 小队名称
      teamDetail: {}, // 小队详情
      type: null,
    }
  },
  created() {
    if(this.userInfo && this.userInfo.is_team_master != 1){
      this.$router.replace('/user')
    }
    if(this.$route.params.id) {
      this.id = this.$route.params.id
      this.getTeamDetail()
    }
    if(this.$route.query.type){
      this.type = this.$route.query.type
    }
  },
  methods: {
    // 获取小队详情
    getTeamDetail() {
      let url = this.$api.TeamGroups + '/'+ this.id
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.teamDetail= res.data.data
          this.name = this.teamDetail.name
        }
      })
    },
    // 小队跳转
    teamNumber() {
      this.$router.push({path: `/management-team/team/${this.id}`, query: {type: this.type}})
    },
    // 修改小队名称

    editName: _.debounce(function (){
      if(this.name == ''){
        return false
      }
      let url = this.$api.teamGroups + '/' + this.id
      let data = {
        id: this.id,
        name: this.name
      }
      this.$http.put(url, data, true).then(res => {
        if(res.data.success) {
          this.getTeamDetail()
          this.edit_team = false
          this.$toast('修改成功')
        }
      })
    }, 500),
    // 小队名称修改弹框
    editTeam() {
      if(this.type == null){
        this.edit_team = true
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  beforeCreate(){
    document.querySelector('body').setAttribute('style', 'background:#F7F7F7')
  },
  beforeUnmount() {
    document.querySelector('body').setAttribute('style', 'background:#FFFFFF')
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-management/edit-team.scss";
.cont-fixed{
  background-color: rgba(247, 247, 247, 1);
}
</style>

